import { PaletteMode, alpha, lighten } from '@mui/material';

const white = '#FFFFFF';
const black = '#000000';

const green1 = '#3DA137';
const green2 = '#63B35F';

const yellow1 = '#BAA432';
const yellow2 = '#C7B65B';

const orange2 = '#C27B55';

const red1 = '#B3332B';
const red2 = '#C25B55';
const red3 = '#7D231E';

const good = {
  main: green1,
  light: alpha(green1, 0.15),
  dark: alpha(green1, 0.2),
  contrastText: alpha(white, 0.9),
  background: alpha(green1, 0.07),
};

const concern = {
  main: yellow1,
  light: alpha(yellow1, 0.15),
  dark: alpha(yellow1, 0.2),
  contrastText: alpha(white, 0.9),
  background: alpha(yellow1, 0.07),
};

const bad = {
  main: red1,
  light: alpha(red1, 0.18),
  dark: alpha(red1, 0.23),
  contrastText: alpha(white, 0.9),
  background: alpha(red1, 0.07),
};

export const createPalette = (mode: PaletteMode = 'light') => {
  return {
    mode,
    primary: {
      main: '#4240D1',
      light: '#6766DA',
      dark: '#2E2C92',
      contrastText: alpha(white, 0.9),
      background: alpha('#4240D1', 0.07),
    },
    secondary:
      mode === 'light'
        ? {
            main: '#050712',
            light: '#33343C',
            dark: '#000',
            contrastText: alpha(white, 0.9),
            background: alpha('#050712', 0.07),
          }
        : {
            main: '#faf8ed',
            light: '#fff',
            dark: '#cccbc3',
            contrastText: alpha(black, 0.9),
            background: alpha('#faf8ed', 0.07),
          },
    /*
     The following colors should match to the error color:
     any color that should conveys a failure or an error, or anything red
    */
    error:
      mode === 'light'
        ? {
            main: red1,
            light: red2,
            dark: red3,
            contrastText: alpha(white, 0.9),
            background: alpha(red1, 0.07),
          }
        : {
            main: red1,
            light: red2,
            dark: red3,
            contrastText: alpha(black, 0.9),
            background: alpha(red1, 0.07),
          },
    /*
     The following colors should match to the error color:
     #ECD9D3 (background), any other color that should conveys a warning
    */
    warning:
      mode === 'light'
        ? {
            main: '#B35A2B',
            light: orange2,
            dark: '#7D3E1E',
            contrastText: alpha(white, 0.9),
            background: alpha('#B35A2B', 0.07),
          }
        : {
            main: '#B35A2B',
            light: orange2,
            dark: '#7D3E1E',
            contrastText: alpha(black, 0.9),
            background: alpha('#B35A2B', 0.07),
          },
    /*
     The following colors should match to the success color:
     #00853E, #058D4A (main), #E6F3EC (background), any other color that should conveys success,
     or anything green
    */
    success:
      mode === 'light'
        ? {
            main: green1,
            light: green2,
            dark: '#2A7026',
            contrastText: alpha(white, 0.9),
            background: alpha(green1, 0.07),
          }
        : {
            main: green1,
            light: green2,
            dark: '#2A7026',
            contrastText: alpha(black, 0.9),
            background: alpha(green1, 0.07),
          },
    info:
      mode === 'light'
        ? {
            main: '#2B7EB3',
            light: '#5597C2',
            dark: '#1E587D',
            contrastText: alpha(white, 0.9),
            background: alpha('#2B7EB3', 0.07),
          }
        : {
            main: '#2B7EB3',
            light: '#5597C2',
            dark: '#1E587D',
            contrastText: alpha(black, 0.9),
            background: alpha('#2B7EB3', 0.07),
          },
    numa:
      mode === 'light'
        ? {
            main: '#4536A1',
            light: '#6A5EB3',
            dark: '#302570',
            contrastText: alpha(white, 0.9),
            background: alpha('#4536A1', 0.07),
          }
        : {
            main: '#4536A1',
            light: '#6A5EB3',
            dark: '#302570',
            contrastText: alpha(white, 0.9),
            background: alpha('#4536A1', 0.07),
          },
    highlight: {
      main: '#FD12D7',
      light: '#FA34DA',
      dark: '#D404B2',
      contrastText: alpha(white, 0.9),
      background: alpha('#FD12D7', 0.07),
    },
    customer:
      mode === 'light'
        ? {
            main: '#000000',
            light: '#333333',
            dark: '#000000',
            contrastText: alpha(white, 0.9),
            background: alpha('#000000', 0.07),
          }
        : {
            main: '#FFFFFF',
            light: '#CCCCCC',
            dark: '#666666',
            contrastText: alpha(black, 0.9),
            background: alpha('#FFFFFF', 0.07),
          },
    user:
      mode === 'light'
        ? {
            main: '#226CB3',
            light: '#4E89C2',
            dark: '#174B7D',
            contrastText: alpha(white, 0.9),
            background: alpha(white, 0.07),
          }
        : {
            main: '#226CB3',
            light: '#4E89C2',
            dark: '#174B7D',
            contrastText: alpha(white, 0.9),
            background: alpha(white, 0.07),
          },
    appointment:
      mode === 'light'
        ? {
            main: '#32BAB9',
            light: '#5BC7C7',
            dark: '#238281',
            contrastText: alpha(white, 0.9),
            background: alpha('#32BAB9', 0.07),
          }
        : {
            main: '#32BAB9',
            light: '#5BC7C7',
            dark: '#238281',
            contrastText: alpha(black, 0.9),
            background: alpha('#32BAB9', 0.07),
          },
    order:
      mode === 'light'
        ? {
            main: '#7875C9',
            light: '#6A5EB3',
            dark: '#302570',
            contrastText: alpha(white, 0.9),
            background: alpha('#7875C9', 0.07),
          }
        : {
            main: '#7875C9',
            light: '#6A5EB3',
            dark: '#302570',
            contrastText: alpha(white, 0.9),
            background: alpha('#7875C9', 0.07),
          },
    note:
      mode === 'light'
        ? {
            main: yellow1,
            light: yellow2,
            dark: '#827223',
            contrastText: alpha(white, 0.9),
            background: alpha(yellow1, 0.07),
          }
        : {
            main: yellow1,
            light: yellow2,
            dark: '#827223',
            contrastText: alpha(black, 0.9),
            background: alpha(yellow1, 0.07),
          },
    /*
     The following colors should match to the success color:
     #003EA5, #2368AB (main),any other color that should conveys the concept of a task,
    */
    task:
      mode === 'light'
        ? {
            main: '#2B53B3',
            light: '#5575C2',
            dark: '#1E3A7D',
            contrastText: alpha(white, 0.9),
            background: alpha('#2B53B3', 0.07),
          }
        : {
            main: '#2B53B3',
            light: '#5575C2',
            dark: '#1E3A7D',
            contrastText: alpha(white, 0.9),
            background: alpha('#2B53B3', 0.07),
          },
    phonecall:
      mode === 'light'
        ? {
            main: '#2B70B3',
            light: '#558CC2',
            dark: '#1E4E7D',
            contrastText: alpha(white, 0.9),
            background: alpha('#2B70B3', 0.07),
          }
        : {
            main: '#2B70B3',
            light: '#558CC2',
            dark: '#1E4E7D',
            contrastText: alpha(white, 0.9),
            background: alpha('#2B70B3', 0.07),
          },
    payment:
      mode === 'light'
        ? {
            main: '#064A22',
            light: '#376E4E',
            dark: '#043317',
            contrastText: alpha(white, 0.9),
            background: alpha('#064A22', 0.07),
          }
        : {
            main: '#064A22',
            light: '#376E4E',
            dark: '#043317',
            contrastText: alpha(white, 0.9),
            background: alpha('#064A22', 0.07),
          },
    report:
      mode === 'light'
        ? {
            main: '#B35A2B',
            light: '#C27B55',
            dark: '#7D3E1E',
            contrastText: alpha(white, 0.9),
            background: alpha('#B35A2B', 0.07),
          }
        : {
            main: '#B35A2B',
            light: '#C27B55',
            dark: '#7D3E1E',
            contrastText: alpha(black, 0.9),
            background: alpha('#B35A2B', 0.07),
          },

    // Scale 0-10
    tenScale10: good,
    tenScale9: good,
    tenScale8: {
      main: green2,
      light: alpha(green2, 0.15),
      dark: alpha(green2, 0.2),
      contrastText: alpha(white, 0.9),
      background: alpha(green2, 0.07),
    },
    tenScale7: {
      main: yellow2,
      light: alpha(yellow2, 0.15),
      dark: alpha(yellow2, 0.2),
      contrastText: alpha(white, 0.9),
      background: alpha(yellow2, 0.07),
    },
    tenScale6: concern,
    tenScale5: {
      main: '#C29555',
      light: alpha('#C29555', 0.17),
      dark: alpha('#C29555', 0.22),
      contrastText: alpha(white, 0.9),
      background: alpha('#C29555', 0.07),
    },
    tenScale4: {
      main: orange2,
      light: alpha(orange2, 0.18),
      dark: alpha(orange2, 0.23),
      contrastText: alpha(white, 0.9),
      background: alpha(orange2, 0.07),
    },
    tenScale3: {
      main: red2,
      light: alpha(red2, 0.18),
      dark: alpha(red2, 0.23),
      contrastText: alpha(white, 0.9),
      background: alpha(red2, 0.07),
    },
    tenScale2: {
      main: red1,
      light: alpha(red1, 0.18),
      dark: alpha(red1, 0.23),
      contrastText: alpha(white, 0.9),
      background: alpha(red1, 0.07),
    },
    tenScale1: {
      ...bad,
      light: alpha(red3, 0.22),
      dark: alpha(red3, 0.27),
    },
    tenScale0: {
      ...bad,
      light: alpha(red3, 0.22),
      dark: alpha(red3, 0.27),
    },

    // Scale 1.0-5.0
    fiveScale5: good,
    fiveScale4: {
      main: green2,
      light: alpha(green2, 0.15),
      dark: alpha(green2, 0.2),
      contrastText: alpha(white, 0.9),
      background: alpha(green2, 0.07),
    },
    fiveScale3: concern,
    fiveScale2: {
      main: orange2,
      light: alpha(orange2, 0.18),
      dark: alpha(orange2, 0.23),
      contrastText: alpha(white, 0.9),
      background: alpha(orange2, 0.07),
    },
    fiveScale1: bad,

    // Scale
    good,
    concern,
    bad,

    divider: alpha(mode === 'light' ? black : white, 0.1),
    background: {
      default: mode === 'light' ? white : '#0F0E1B',
      // paper: mode === 'light' ? darken(white, 0.1) : '#21232D',
      paper: mode === 'light' ? white : '#0F0E1B',
      brand: '#2D2BB3',
      dark: black,
    },
    text: {
      primary: mode === 'light' ? lighten(black, 0.1) : white,
      secondary: mode === 'light' ? '#08064A' : '#9D9AE3',
      disabled: alpha(mode === 'light' ? black : white, 0.25),
      hint: alpha(mode === 'light' ? black : white, 0.7),
    },
  };
};

declare module '@mui/material/styles/createPalette' {
  interface TypeText {
    hint: string;
  }

  interface TypeBackground {
    contrast: string;
    dark: string;
  }
}
declare module '@mui/material/styles' {
  interface SimplePaletteColorOptions {
    background: string;
  }
  interface PaletteColor {
    background: string;
  }
  interface Palette {
    report: Palette['primary'];
    numa: Palette['primary'];
    highlight: Palette['primary'];
    customer: Palette['primary'];
    user: Palette['primary'];
    appointment: Palette['primary'];
    order: Palette['primary'];
    note: Palette['primary'];
    task: Palette['primary'];
    phonecall: Palette['primary'];
    payment: Palette['primary'];

    good: Palette['primary'];
    concern: Palette['primary'];
    bad: Palette['primary'];

    tenScale10: Palette['primary'];
    tenScale9: Palette['primary'];
    tenScale8: Palette['primary'];
    tenScale7: Palette['primary'];
    tenScale6: Palette['primary'];
    tenScale5: Palette['primary'];
    tenScale4: Palette['primary'];
    tenScale3: Palette['primary'];
    tenScale2: Palette['primary'];
    tenScale1: Palette['primary'];
    tenScale0: Palette['primary'];

    fiveScale5: Palette['primary'];
    fiveScale4: Palette['primary'];
    fiveScale3: Palette['primary'];
    fiveScale2: Palette['primary'];
    fiveScale1: Palette['primary'];
  }
  interface PaletteOptions {
    report?: PaletteOptions['primary'];
    inbox?: PaletteOptions['primary'];
    numa?: PaletteOptions['primary'];
    highlight?: PaletteOptions['primary'];
    customer?: PaletteOptions['primary'];
    user?: PaletteOptions['primary'];
    appointment?: PaletteOptions['primary'];
    order?: PaletteOptions['primary'];
    note?: PaletteOptions['primary'];
    task?: PaletteOptions['primary'];
    phonecall?: PaletteOptions['primary'];
    payment?: PaletteOptions['primary'];
  }
}
